<script>
/**
 * Nav-bar Component
 */

// import Breadcumb from "../components/widgets/breadcumb";
import moment from 'moment'
import newFolder from "../components/widgets/newFolder.vue";
import NewFile from "../components/widgets/newFile.vue";
import Profile from "../components/widgets/profile.vue";
import Password from "../components/widgets/editPassword.vue";
import EditProfile from "../components/widgets/editProfile.vue";
import DeleteAccount from "./deleteAccount.vue"
import SidebarRight from "./side-bar-right.vue"
import firebase from "firebase/app";
import "firebase/messaging";

import Storage from "../api/storage";

export default {
  components: {newFolder,NewFile,
  // Breadcumb,
  SidebarRight,
  Profile,Password,EditProfile,DeleteAccount},
  data() {
    return {
      keyword: "",
      showDismissibleAlert : true,
      landing : process.env.VUE_APP_LANDING,
      filetype: ".txt",
      listNotifChat: [],
      sa: 5368709120,
      su: 5368709120
    }
  },
  computed: {
    dateCancelAt () {
      return this.$store.getters['user/dateCancel']
    },
    currentRouteName () {
      return this.$route.name
    },
    currentKeyword(){
      return this.$store.getters[this.$store.getters['state/state']+'/keyword']
    },
    originalSize(){
      return this.$store.getters['user/size_directory'];
    },
    originalUsed(){
      return this.$store.getters['user/total_used'];
    },
    statusStorage(){
      var sizeDirectory = this.$store.getters['user/size_directory']
      var usedDirectory = this.$store.getters['user/total_used']
      var total = sizeDirectory - usedDirectory
      var sttStorage
      if (total <= 0){
        sttStorage = 'full'
      } else if (total <= 104857600) {
        sttStorage = 'almost full'
      } else {
        sttStorage = 'almost full'
      }
      return sttStorage
    },
    notification() {
      return this.$store.getters['notif/notifs']
    },
    // notification2() {
    //   var notif = this.$store.getters['chat/notifChatAndEncrypt']
    //   var newNotif = notif.sort(function(a,b){return b.time - a.time})
    //   return newNotif
    // },
    listingsShare: {
      //getter
      get: function () {
        let data = this.$store.getters['share/items']
        return data
      },
      //setter
      set: function (newValue) {
        this.$store.commit("share/SET_ITEMS",newValue)
      }
    },
    listings: {
      // getter
      get: function () {
        var data = this.$store.getters[this.$store.getters['state/state']+'/recentList'];
        return data.filter(v=>!v.isFolder)
      },
      // setter
      set: function (newValue) {
        this.$store.commit(this.$store.getters['state/state']+"/SET_RECENTLIST",newValue);
      }
    },
    nama() {
          return this.$store.getters['user/fullname']
    },
    message() {
          return this.$store.getters['error/message']
    },
    success() {
          return this.$store.getters['error/success']
    },
    error() {
          return this.$store.getters['error/error']
    },
    showBreadCumb() {
          return this.$store.getters['storage/showBreadcumb']
    },
    subTitle() {
          return this.$store.getters['storage/subTitle']
    },
    selectedField() {
          return this.$store.getters['storage/selected']
    },
    folderOnSelected(){
      return this.selectedField.filter((v)=>v.isFolder);
    },
    grid(){
        return this.$store.getters['storage/gridView'];
    },
    state(){
        return this.$store.getters['state/state'];
    },
    selectedWorkgroup(){
        return this.$store.getters['workgroup/workgroup'];
    },
    showDetails(){
        return this.$store.getters['workgroup/showParticipants'];
    },
    canAccess(){
        var id = this.$store.getters['user/id'];
        var group = this.$store.getters['workgroup/workgroup'];
        var st = this.$store.getters['state/state'];
        if(st == "storage"){
          return true
        }else{
          if(id == group.owner_id){
            return true;
          }else{
            return group.access_type != "View";
          }
        }
    }
  },
  // watch: {
  //   keyword(){
  //     this.search()
  //   }
  // },
  beforeMount(){
    this.$store.dispatch('notif/getAllNotification')
  },
  mounted(){
    this.$store.getters['storage/notifToggle']
    this.$store.dispatch('storage/isStorageFull')
    this.$store.dispatch('storage/statSubs')
    this.key()

    // if(this.selectedWorkgroup != null && this.state == 'workgroup'){
    //   this.$store.dispatch('chat/openChat')
    //   this.$store.getters['chat/sortParticipant']
    // }
    // document.getElementById("uploadLoading").style.display = "block";
    //   var filesData = {
    //     id : "tpm.png359467",
    //     name : "Folder A",
    //     done : true,
    //     status : 4,//1=prepare,2=uploading,3=done,4=failed,
    //     size : 359467,
    //     type : "image/png",
    //     percentage : 50,
    //     error : "",
    //     isFolder : true,
    //     total : 1,
    //     uploaded : 0
    //   }
    //   this.$store.commit("upload/ADD_FILES",filesData);
  },
  created() {
      firebase
        .messaging()
        .requestPermission()
        .then(() => {
          firebase
            .messaging()
            .getToken()
            .then((token) => {
              this.$store.dispatch('firebase/storeFirebase', token)
              var firefoxAgent = this.$browserDetect.isFirefox
              var safariAgent = this.$browserDetect.isSafari
              var operaAgent = this.$browserDetect.isOpera
              if(!firefoxAgent && !safariAgent && !operaAgent){
                this.receiveMessage();
              }
            });
        })
        .catch((err) => {
        });
  },
  methods: {
    readNotif(item){
      this.$store.dispatch("notif/readNotif",item)
    },
    joinWg(item){
      var payload = {
        user_id: item.user_id,
        workgroup_id: item.workgroup_id
      }
      this.$store.dispatch('workgroup/joinWg', payload)
      .then((result)=>{
        this.$store.dispatch("workgroup/retrieve",result._id)
        .then(()=>{
          if(this.$router.currentRoute.name != "home"){
            this.$router.push({name:"home"}).catch(()=>{});
          }
          this.$forceUpdate();
          this.$store.dispatch("workgroup/showDetails");
        })
      })
      this.$store.dispatch("notif/readNotif",item)
    },
    key(){
      this.keyword = this.currentKeyword
    },
    upgradePlan(){
      this.$router.push('/packages')
    },
    closeNotifAlert(){
      var notifAlert = document.getElementById('alert-storage-v3')
      var mainContent = document.getElementById('content-pages')
      notifAlert.style.display = 'none'
      mainContent.style.marginTop = '0px'
    },
    closeStatusSubsAlert(){
      var notifAlert = document.getElementById('alert-status-subs-v3')
      var mainContent = document.getElementById('content-pages')
      notifAlert.style.display = 'none'
      mainContent.style.marginTop = '0px'
    },
    openParent(item){
      // this.$store.commit('chat/SET_NEW_NOTIF_ENCRYPT', list)
      this.$store.dispatch("notif/readNotif",item);
    },
    getImage(filename){
      var storageUtils = new Storage();
      return storageUtils.getImage(filename.substr(filename.lastIndexOf('.') + 1))
    },
    receiveMessage() {
        firebase.messaging().onMessage((payload) => {
          var data = payload.data
          var newData = JSON.parse(data.data)
          // if(newData.datachat != null){
          //   this.$store.dispatch('chat/sendFileToChatFirebase', newData)
          // }
          // this.$store.dispatch('firebase/setNotifFirebase', payload.data)
          this.$store.dispatch('firebase/setNotifFirebase', newData)
          this.$store.dispatch(this.state+"/listing")
        });
    },
    sortNotification(){
      var notifMsg = this.$store.getters['chat/notif']
      var notifAllMsg = notifMsg.sort(function(a,b){return b.time - a.time})

      this.$store.dispatch('chat/setNotifChatGroup', notifAllMsg)
      var chatNotif = this.$store.getters["chat/notifChatGroup"]
      this.notification = chatNotif
    },
    openWorkgroup(item){
      this.$store.dispatch("notif/readNotif",item)
      .then(()=>{
        this.$ga.event('workgroup', 'actionx', 'labelx', 1)
        this.$store.commit(this.state+"/SET_KEYWORD","");
        this.$store.commit(this.state+"/SET_PARENT_ID","");
        this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
        this.$store.dispatch("workgroup/retrieve",item.group_by)
        .then(()=>{
          if(this.$router.currentRoute.name != "chat"){
            this.$router.push({name:"chat"});
          }
          this.$store.dispatch('chat/openChat')
          this.$forceUpdate();
          this.$store.dispatch("workgroup/showDetails");
        });
        var dropdownWG = document.querySelector('.side-menu-wg-open')
        dropdownWG.classList.remove('side-menu-wg-open')
      })
    },
    dateNotif(date){
      return moment(date * 1000).fromNow()
    },
    downloadMultiple(){
      var onlyFiles = this.selectedField.filter((v)=>!v.isFolder);
      var data = {
          directory : this.$store.getters['user/directory'],
          list_id : onlyFiles.map((v)=>{
            return {id:v._id}
          })
      }
      this.$store.dispatch("storage/multipleDownload",data);

    },
    deleteMultiple(){
        this.$root.$emit('bv::show::modal', 'modaltoTrash');
    },
    move(){
        this.$root.$emit('bv::show::modal', 'modalMove');
    },
    copy(){
        this.$root.$emit('bv::show::modal', 'modalCopy');
    },
    changeView(){
        this.$store.commit("storage/SET_SHOW_GRID",!this.grid);
    },
    closeAlertE(){
        this.$store.commit("error/SET_MESSAGE","");
        this.$store.commit("error/SET_ERROR",false);
    },
    closeAlertS(){
        this.$store.commit("error/SET_MESSAGE","");
        this.$store.commit("error/SET_SUCCESS",false);
    },
    sliceChar(str){
      return str.length > 16 ? str.substring(0,16)+" ..." : str;
    },
    refresh(){
      if(this.subTitle == "Recent Files"){
        this.$store.dispatch(this.state+"/recent")
      }else if(this.subTitle == "Share With Me"){
        this.$store.commit("share/SET_SHOWFILE",false);
        this.$store.dispatch("share/listing")
      }
      else{
        this.$store.dispatch(this.state+"/listing",this.showBreadCumb ? "" : '1')
      }
    },
    search(){
      if(this.$route.path != '/search'){
        this.$router.push('/search')
        this.$store.commit(this.state+"/SET_KEYWORD",this.keyword);
        this.$store.dispatch(this.state+"/listing")
      } else {
        this.$store.commit(this.state+"/SET_KEYWORD",this.keyword);
        this.$store.dispatch(this.state+"/listing")
      }
    },
    searchEnter(){
      if(this.$route.path != '/search'){
        this.$router.push('/search')
        this.$store.commit(this.state+"/SET_KEYWORD",this.keyword);
        this.$store.commit(this.state+"/SET_PARENT_ID","")
        this.$store.dispatch(this.state+"/listing")
      } else {
        this.$store.commit(this.state+"/SET_KEYWORD",this.keyword);
        this.$store.commit(this.state+"/SET_PARENT_ID","")
        this.$store.dispatch(this.state+"/listing")
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    createFile(type){
      this.filetype = type;
      this.$root.$emit('bv::show::modal', 'modalCreate');
    },
    uploadFile(){
      document.getElementById("fileUpload").click()
    },
    uploadFolder(){
      document.getElementById("folderUpload").click()
    },
    checkNested(list){
      return new Promise((resolve,reject)=>{
        var haveNested = false;
        for (let i = 0; i < list.length; i++) {
            haveNested = list[i].webkitRelativePath.split("/").length > 2
        }
        if(haveNested){
          reject(haveNested);
        }else{
          resolve(haveNested);
        }
      });
    },
    // chooseFolder(){
    //   let files = document.getElementById("folderUpload").files;

    //   if(files.length <= 10){

    //   this.checkNested(files)
    //     .then(() => {
    //       var self = this;
    //     document.getElementById("uploadLoading").style.display = "block";
    //     let promise = [];
    //     var filesData = {
    //       id : (Math.random() + 1).toString(36).substring(3),
    //       name : files[0].webkitRelativePath.split("/")[0],
    //       done : false,
    //       status : 2,//1=prepare,2=uploading,3=done,4=failed,
    //       size : 0,
    //       type : 0,
    //       percentage : 0,
    //       error : "",
    //       isFolder : true,
    //       total : files.length,
    //       uploaded : 0
    //     }
    //     this.$store.commit("upload/ADD_FILES",filesData);
    //     for (let f = 0; f < files.length; f++) {
    //       if(files[f].size > 0){
    //         var metadata = {
    //               filename: files[f].name,
    //               filetype: files[f].name.split('.').pop(),
    //               directory: self.$store.getters["user/directory"],
    //               path: files[f].webkitRelativePath,
    //               type: "2"
    //           }
    //         promise.push(
    //           this.$store.dispatch(this.state+"/uploadTus",{
    //             file : files[f],
    //             metadata : metadata,
    //             filesData : filesData,
    //             isFolder : true
    //           })
    //         );
    //       }
    //     }
    //     Promise.all(promise).then((data)=>{
    //       var paramUpload = {
    //         parent_id : this.$store.getters[this.state+"/parent_id"],
    //         data : data
    //       }
    //       if(this.state == "workgroup"){
    //         paramUpload = {
    //           workgroup_id : this.selectedWorkgroup._id,
    //           owner_id : this.selectedWorkgroup.owner_id,
    //           parent_id : this.$store.getters[this.state+"/parent_id"],
    //           data : data
    //         }
    //       }
    //       this.$store.dispatch(this.state+"/uploadFolder",paramUpload).then((success)=>{
    //         this.$store.commit("upload/STATUS",{id:filesData.id,status:3,error:success});
    //         this.$store.commit("upload/DONE",{id:filesData.id,done:true});
    //       }).catch((error)=>{
    //         this.$store.commit("upload/STATUS",{id:filesData.id,status:4,error:error});
    //       })
    //     });
    //     })

    //     .catch(() => {
    //       var filesData = {
    //       id : (Math.random() + 1).toString(36).substring(3),
    //       name : 'Test',
    //       done : false,
    //       status : 4,//1=prepare,2=uploading,3=done,4=failed,
    //       size : 0,
    //       type : 0,
    //       percentage : 0,
    //       error : "Cannot upload nested folder",
    //       isFolder : true,
    //       total : files.length,
    //       uploaded : 0
    //     }
    //     this.$store.commit("upload/ADD_FILES",filesData);
    //     document.getElementById("uploadLoading").style.display = "block";
    //     })
    //   }
    //   else{
    //     var filesData = {
    //       id : (Math.random() + 1).toString(36).substring(3),
    //       name : 'Test',
    //       done : false,
    //       status : 4,//1=prepare,2=uploading,3=done,4=failed,
    //       size : 0,
    //       type : 0,
    //       percentage : 0,
    //       error : "Cannot upload more than 10 files",
    //       isFolder : true,
    //       total : files.length,
    //       uploaded : 0
    //     }
    //     this.$store.commit("upload/ADD_FILES",filesData);
    //     document.getElementById("uploadLoading").style.display = "block";
    //   }
    // },
    // choosFile(){
    //     let files = document.getElementById("fileUpload").files;
    //     document.getElementById("uploadLoading").style.display = "block";
    //     let promise = [];
    //     for (let i = 0; i < files.length; i++) {
    //       if(files.length <= 10){
    //         if(files[i].size <= 0){
    //           var filesData = {
    //             id : (Math.random() + 1).toString(36).substring(2),
    //             name : files[i].name,
    //             done : true,
    //             status : 4,//1=prepare,2=uploading,3=done,4=failed,
    //             size : files[i].size,
    //             type : files[i].type,
    //             percentage : 0,
    //             error : "File not contain any data, Please create file on jaybod!",
    //             isFolder : false,
    //             total : 1,
    //             uploaded : 0
    //           }
    //           this.$store.commit("upload/ADD_FILES",filesData);
    //         }else{
    //           filesData = {
    //             id : (Math.random() + 1).toString(36).substring(2),
    //             name : files[i].name,
    //             done : false,
    //             status : 2,//1=prepare,2=uploading,3=done,4=failed,
    //             size : files[i].size,
    //             type : files[i].type,
    //             percentage : 0,
    //             error : "",
    //             isFolder : false,
    //             total : 1,
    //             uploaded : 0
    //           }
    //           this.$store.commit("upload/ADD_FILES",filesData);
    //           var metadata = {
    //                 filename: files[i].name,
    //                 filetype: files[i].name.split('.').pop()
    //           }
    //           promise.push(
    //             this.$store.dispatch(this.state+"/uploadTus",{
    //               file : files[i],
    //               metadata : metadata,
    //               filesData : filesData,
    //               isFolder : false
    //             })
    //           );
    //         } 
    //       }else{
    //         this.$store.commit("error/SET_MESSAGE","Cannot upload more than 10 files");
    //         this.$store.commit("error/SET_ERROR",true);
    //       }
    //     }
    //     Promise.all(promise).then(()=>{
    //     });
    // },
    openDetailWorkgroup(){
      this.$store.dispatch("workgroup/showDetails");
    },
    toggleSwitchShow(){
      var box = document.getElementById('box-notif')
      var show = this.$store.getters['storage/notifToggle']

      if(show){
        // box.style.opacity = '0'
        // box.style.maxHeight = '0px'
        box.style.display = 'none'
        this.$store.commit("storage/SET_NOTIF_TOGGLE", false)
      }
    },
    toggleNotification(){
      var box = document.getElementById('box-notif')
      var show = this.$store.getters['storage/notifToggle']

      if(show){
        // box.style.opacity = '0'
        // box.style.maxHeight = '0px'
        box.style.display = 'none'
        this.$store.commit("storage/SET_NOTIF_TOGGLE", false)
      } else {
        box.style.opacity = '1'
        box.style.maxHeight = '80vh'
        box.style.display = 'block'
        this.$store.commit("storage/SET_NOTIF_TOGGLE", true)
      }
    },
    closeNotif(){
        var box = document.getElementById('box-notif')

        // box.style.opacity = '0'
        // box.style.maxHeight = '0px'
        box.style.display = 'none'
        this.$store.commit("storage/SET_NOTIF_TOGGLE", false)

    }
  }
};

</script>

<template>
  <header id="page-topbar">
    <!-- <input id="fileUpload" type="file" multiple hidden @change="choosFile">
    <input id="folderUpload" type="file" hidden @change="chooseFolder" webkitdirectory mozdirectory> -->
    <NewFile :filetype="filetype" />
    <Profile/>
    <Password/>
    <EditProfile/>
    <DeleteAccount/>
    <div id="alert-storage-v3">
      <div class="d-flex justify-content-between align-items-center">
        <span>Your storage is {{statusStorage}}. Get more space by upgrading your plan now. <span>Upgrade plan</span></span>
        <img @click="closeNotifAlert()" class="cursor-pointer" src="@/assets/images/icon/close-btn-white.svg" alt="">
      </div>
    </div>
    <div id="alert-status-subs-v3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex flex-column">
          <span>Your plan cancellation will be effective at the end of your billing period: {{dateCancelAt}}.</span>
          <span>After that, your account will be locked and you can’t access Jaybod again unless you <span>Renew</span> or <span>Upgrade</span> your plan.</span>
        </div>
        <img @click="closeStatusSubsAlert()" class="cursor-pointer" src="@/assets/images/icon/close-btn-white.svg" alt="">
      </div>
    </div>
    <b-modal id="modalContainer" :hide-footer="true" :hide-header="true" centered title="BootstrapVue">
      <newFolder/>
    </b-modal>
    <div class="navbar-header">
      <div class="d-flex">
        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item d-lg-none"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>
      <div class="w-100" >
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="d-flex flex-fill ml-4 show-desktop">
            <!-- <form class="pb-0 pt-4 d-none d-lg-block"> -->
            <form class="pb-0 pt-4 d-none d-lg-block" @submit.prevent="searchEnter">
              <div class="">
                <input
                  id="inputSearch"
                  v-model="keyword"
                  @keyup="keyword.length >= 3 ? search() : ''"
                  class="form-control login-input-style search-navbar-box"
                  placeholder="Search..."
                  />
                <!-- <span class="bx bx-search-alt search-navbar-icon"></span> -->
                <img src="@/assets/images/icon/search-black.svg" alt="" class="search-navbar-icon">
              </div>
            </form>
          </div>
          <div class="d-flex align-items-center justify-content-end w-100">
            <div class="hide-desktop d-flex justify-content-beetwen w-100">
              <form class="app-search-sm pb-0 pt-4" @submit.prevent="searchEnter">
                <div class="position-relative">
                  <input type="search"
                    v-model="keyword"
                    @keyup="keyword.length >= 3 ? search() : ''"
                    class="form-control login-input-style" 
                    placeholder="Search..." />
                  <span class="bx bx-search-alt"></span>
                </div>
              </form>
              <!-- <b-dropdown id="dropdown-dropup" size="lg"  dropup right class="m-2 min-width-300 bg-transparent"> -->
                <!-- <template #button-content>
                  <img src="@/assets/images/icon/Search.png" alt height="100%" />
                </template>
                <form class="p-3"  @submit.prevent="searchEnter">
                  <div class="form-group m-0">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control login-input-style"
                        placeholder="Search ..."
                        v-model="keyword"
                        aria-label="Recipient's username"
                        @keydown="search()"
                      />

                    </div>
                  </div>
                </form> -->
              <!-- </b-dropdown> -->
            </div>
            <!-- <div>
              <b-dropdown id="dropdown-dropup" dropup right class="m-2 bg-transparent">
                <template #button-content>
                  <img src="@/assets/images/icon/Notification.png" alt height="100%" />
                </template>
                <b-dropdown-item href="#">Action</b-dropdown-item>
                <b-dropdown-item href="#">Another action</b-dropdown-item>
                <b-dropdown-item href="#">Something else here</b-dropdown-item>
              </b-dropdown>
            </div> -->

            <div class="mt-3 mr-3">
              <div class="notif-navbar" @click="toggleNotification">
                <div v-if="notification.length <= 0">
                  <img src="@/assets/images/icon/s-lonceng.svg" alt="">
                </div>
                <div v-else>
                  <img src="@/assets/images/icon/s-lonceng.svg" alt="">
                  <span class="notif-badge"></span>
                </div>
              </div>
              <div class="notif-box" id="box-notif">
                <div class="notif-header">
                  <div v-if="notification.length <= 0" class="notif-header-right">
                    <p>Notification</p>
                  </div>
                  <div v-else class="notif-header-right">
                    <p>Notification</p>
                    <p><span>{{notification.length}}</span></p>
                  </div>
                  <div role="button" @click="closeNotif">
                    <img src="@/assets/images/icon/Close.svg" alt="">
                  </div>
                </div>
                <div class="notif-body">
                  <!-- <pre>{{notification2}}</pre> -->
                  <div v-if="notification.length <= 0" class="notif-empty">
                    <img src="@/assets/images/empty-notif.svg" alt="">
                    <div class="notif-empty-text">
                      <p>No notification</p>
                      <p><span class="text-center">When something new arrives, it will show up here</span></p>
                    </div>
                  </div>
                  <div v-else v-for="(notif, index) in notification" :key="index">
                    <div v-if="notif.group_by == null">
                      <div @click="openParent(notif)" class="notif-info">
                        <div class="notif-icon">
                          <img :src="require(`@/assets/images/icon/${notif.icon}`)" alt height="60%" />
                        </div>
                        <div class="notif-text-encrypt">
                          <p class="file-name-notif">{{ notif.title }}</p>
                          <p><span>{{notif.message}}</span></p>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="notif.group_by == 'workgroup'">
                      <div class="notif-info">
                        <div class="notif-icon">
                          <b-avatar size="30px" variant="primary">{{notif.icon}}</b-avatar>
                        </div>
                        <div class="notif-text">
                          <p>{{notif.title}}</p>
                          <p><span>{{notif.message}}</span></p>
                          <button @click="joinWg(notif)" class="notif-btn-join">
                            Join
                          </button>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="notif.group_by == 'workgroup_info'">
                      <div @click="readNotif(notif)" class="notif-info">
                        <div class="notif-icon">
                          <b-avatar size="30px" variant="primary">{{notif.icon}}</b-avatar>
                        </div>
                        <div class="notif-text">
                          <p>{{notif.title}}</p>
                          <p><span>{{notif.message}}</span></p>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div @click="openWorkgroup(notif)" class="notif-info">
                        <div class="notif-icon">
                          <b-avatar size="30px" variant="primary">{{notif.icon}}</b-avatar>
                        </div>
                        <div class="notif-text">
                          <p>{{notif.title}}</p>
                          <p><span>{{notif.message}}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mr-3 mt-3">
              <b-dropdown id="dropdown-dropup" dropup right class="bg-transparent profile-dropdown">
                <template #button-content>
                    <div @click="toggleSwitchShow" class="avatar-xs">
                        <span class="avatar-title rounded-circle">{{nama.charAt(0).toUpperCase()}}</span>
                    </div>
                </template>
                <b-dropdown-header id="dropdown-header-label">
                  <div class="d-flex">
                    <b-avatar variant="primary" :text="nama.charAt(0).toUpperCase()" class="mr-2 avatar-xs"></b-avatar>
                    <div class="d-flex justify-content-start align-items-center">
                      <span class="user-name default-text-style-profile "><span class="letter05">{{nama}}</span></span>
                    </div>
                  </div>
                </b-dropdown-header>
                <b-dropdown-item href="/settings">
                  <img src="@/assets/images/icon/setting.svg" alt height="100%" />
                  <span class="ml-3 default-text-style-profile">Settings</span>
                </b-dropdown-item>
                <b-dropdown-item href="/packages">
                    <img src="@/assets/images/icon/upgrade.svg" alt height="100%" />
                    <span class="ml-3 default-text-style-profile">Upgrade</span>
                  </b-dropdown-item>
                  <!-- <b-dropdown-item href="/payment/histories">
                    <img src="@/assets/images/icon/payment-card.png" alt height="100%" />
                    <span class="ml-3 default-text-style-profile">Payment History</span>
                  </b-dropdown-item> -->
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="/logout">
                    <img src="@/assets/images/icon/logout.svg" alt height="100%" />
                    <span class="ml-3 default-text-style-profile">Sign Out</span>
                  </b-dropdown-item>
              </b-dropdown>
              <!-- <b-avatar variant="primary" @click="openDetailWorkgroup()" button v-if="selectedWorkgroup != null && state == 'workgroup' && !showDetails "  icon="chevron-left" class="mr-2" size="2rem"></b-avatar> -->
            </div>
            <div class="mx-3" id="jarak"></div>
          </div>
        </div>
        <!-- <div class="d-flex justify-content-between align-items-center w-100">
          <div class="ml-2">
            <Breadcumb v-if="showBreadCumb"/>
            <h4 class="mt-4 ml-2 default-text-style bold-normal" v-else>{{state == 'storage' ? subTitle : 'Group Files'}}</h4>
          </div>
          <div class="mt-3" v-if="success || error">
            <b-alert  variant="success" show  v-show="success">
              <div class="d-flex justify-content-between align-items-center">
                  <span class="px-4">{{message}}</span>
                  <span class="cursor-pointer px-2" @click="closeAlertS()">
                    <i class="bx bx-x font-size-16"></i>
                  </span>
              </div>
            </b-alert>
            <b-alert  variant="danger" show  v-show="error">
                  <span class="px-4">{{message}}</span>
                  <span class="cursor-pointer px-2" @click="closeAlertE()">
                    <i class="bx bx-x font-size-16"></i>
                  </span>
            </b-alert>
          </div>
        </div> -->
        <!-- <div class="d-flex justify-content-between align-items-center w-100">
          <div class="mx-2 ml-2 d-flex justify-content-start align-items-center">
            <div class="mx-2 pr-3"  v-if="canAccess">
                <b-dropdown text="New +" variant="primary" class="btn-dropdown active menu-popup ml-2">
                <template #button-content>
                  <img src="@/assets/images/icon/Plus.png"  alt height="100%" />
                  <span class="default-text-style ml-2"><span class="text-white">New</span></span>
                </template>
                  <b-dropdown-item-button v-b-modal.modalContainer>
                        <img src="@/assets/images/icon/folderPlus.png" alt height="100%" />
                        <span class="ml-3">New Folder</span>
                  </b-dropdown-item-button>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="#" @click="uploadFile()" class="">
                        <img src="@/assets/images/icon/Etc.png" alt height="100%" />
                        <span class="ml-3">Upload File</span>
                  </b-dropdown-item>
                  <b-dropdown-item href="#"  @click="uploadFolder()">
                        <img src="@/assets/images/icon/Folder.png" alt height="100%" />
                        <span class="ml-3">Upload Folder</span>
                  </b-dropdown-item>
                  <b-dropdown-item-button @click="createFile('.docx')">
                        <img src="@/assets/images/icon/Docs.png" alt height="100%" />
                        <span class="ml-3">Document</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button  @click="createFile('.xlsx')">
                        <img src="@/assets/images/icon/XLS.png" alt height="100%" />
                        <span class="ml-3">Spreedsheet</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button  @click="createFile('.pptx')">
                        <img src="@/assets/images/icon/PPT.png" alt height="100%" />
                        <span class="ml-3">Presentation</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="createFile('.txt')">
                        <img src="@/assets/images/icon/TXT.png" alt height="100%" />
                        <span class="ml-3">Text</span>
                  </b-dropdown-item-button>
                </b-dropdown>
            </div>
            <div class="pl-3 border-left d-flex" v-if="selectedField.length > 0">
              <button class="btn base-btn btn-storage mx-2" @click="copy()">
                  <span class="default-text-style">Copy</span>
              </button>
              <button class="btn base-btn btn-storage mx-2" @click="move()">
                  <span class="default-text-style">Move</span>
              </button>
              <button class="btn base-btn btn-storage mx-2" @click="downloadMultiple()" v-if="folderOnSelected.length < 1">
                  <span class="default-text-style">Download</span>
              </button>
              <button class="btn base-btn btn-storage mx-2" @click="deleteMultiple()">
                  <span class="default-text-style">Delete</span>
              </button>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <div class="">
              <b-button   v-if="subTitle != 'Edit Password'" variant="outline-secondary" @click="changeView()" class="mr-4 btn-refresh d-lg-block">
                <i class="bx bx-chevron-down font-size-16 ml-4 "></i>
                <span class="ml-1 default-text-style"> {{grid ? "Grid" : "List"}}</span>
              </b-button>
            </div>
            <div class="mr-3">
              <b-button   v-if="subTitle != 'Edit Password'" variant="outline-secondary" @click="refresh()" class="mr-4 btn-refresh hover-blue  d-none d-lg-block">
                <img src="@/assets/images/icon/Refresh.png" class="pb-1" alt height="100%" />
                <span class="ml-2 default-text-style">Refresh</span>
              </b-button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <SidebarRight v-if="currentRouteName != 'Setting' && currentRouteName != 'workgroupSetting' && currentRouteName != 'FileShared'" />
  </header>
</template>

<!-- <style>
#alert-storage-v3{
  background: #00AAFF;
  /* height: 48px; */
  display: none;
  padding: 12px 24px;
}
#alert-storage-v3 span{
  color: #FFFFFF;
  letter-spacing: -1px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}
#alert-storage-v3 span span{
  text-decoration-line: underline;
  cursor: pointer;
}
#alert-status-subs-v3{
  background: #00AAFF;
  /* height: 48px; */
  display: none;
  padding: 12px 24px;
}
#alert-status-subs-v3 span{
  color: #FFFFFF;
  letter-spacing: -1px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}
#alert-status-subs-v3 span span{
  text-decoration-line: underline;
  cursor: pointer;
}
.search-navbar-box{
  position: relative;
  width: 35vw;
  padding-left: 2rem;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: #919394;
}
.search-navbar-icon{
  position: absolute;
  margin-left: 11px;
  top: 41px;
}

.notif-navbar{
  cursor: pointer;
}
.notif-box{
  position: absolute;
  top: 70px;
  right: 60px;
  width: 350px;
  background: #fff;
  max-height: 80vh;
  padding: 20px 0px 20px 0px;
  border-radius: 8px;
  border: 1px solid #E9EAEA;
  z-index: 99;
  display: none;
}
.notif-box .notif-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 15px 0 15px;
  height: 50%;
  /* border-bottom: 1px solid #E9EAEA; */
}
.notif-box .notif-header div img{
  margin-top: -18px;
}
.notif-header .notif-header-right{
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notif-header .notif-header-right p{
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -1px;
  color: #262A2C;
}
.notif-header .notif-header-right p span{
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: -0.5px;
  color: #FFFFFF;
  background: #D42020;
  padding: 2px 8px;
  margin-left: 5px;
  border-radius: 64px;
}
.notif-empty{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.notif-empty img{
  padding: 10px 0;
}
.notif-empty-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 120px;
}
.notif-empty-text p{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -1px;
  color: #262A2C;
}
.notif-empty-text p span{
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -1px;
  color: #919394;
  display: flex;
  margin: 0 66px;
}
.notif-info{
  display: flex;
  width: 100%;
  border-bottom: 1px solid #E9EAEA;
  height: fit-content;
  padding: 8px 0;
  background-color: #E9F5FB;
  cursor: pointer;
}
.notif-icon{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5px;
  width: 20%;
}
.notif-text{
  padding: 5px 5px 5px 0;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.notif-text-encrypt{
  padding: 5px 5px 5px 0;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.notif-text p{
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 2px;
  color: #262A2C;
}
.notif-text p span{
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: -0.5px;
  margin: 0 0 0 0;
  color: #919394;
}
.notif-text-encrypt p{
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 2px;
  color: #262A2C;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* width: 200px; */
}
.notif-text-encrypt p span{
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: -0.5px;
  margin: 0 0 0 0;
  color: #919394;
}
.notif-btn-join{
  font-family: 'Plus Jakarta Sans';
  padding: 8px;
  background: #00AAFF;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  border: none;
  border-radius: 4px;
}

@media (max-width: 767px) {
  .notif-box{
    right: 20px;
  }
}
</style> -->